import React from 'react'

import TabbedInfo from './utilites/tab'
import Checkboxes from './utilites/checkbox'
import ImageGallery from './utilites/product-images'
import MainInfo from './utilites/mainspecs'
import ProductItem from './ProductListingItem'
import Cta from '../sections/Cta'
import Emailer from '../sections/Emailer'

class Decking extends React.Component  {
    constructor(props) {
        super(props)

        const options = props.product.deckOptions.colours
        const cur = 0

        const productOverwrides = props.overwrites === null ? null : props.overwrites

        this.state = { 
            productOverwrides : props.overwrites === null ? null : props.overwrites,
            option: options[cur],
            title: props.product.title,
            mainImage: {
                url: options[cur].image[0].sourceUrl,
                alt: options[cur].image[0].altText,
            },
            thumbnails: options[cur].image,
            price: null,
            dimensions: options[cur].dimensions,
            colours: options,
            desc: options[cur].description,
            spec: options[cur].specifications,
            reversible: options[cur].reversible,
            antiSlip: {
                isSet: options[cur].antiSlipParent.antiSlip,
                price: options[cur].antiSlipParent.antiSlipPrice,
                image: {
                    url: options[cur].antiSlipParent.image ? options[cur].antiSlipParent.image.sourceUrl : null,
                    alt: options[cur].antiSlipParent.image ? options[cur].antiSlipParent.image.altText : null,
                },
            },
            checkbox: 0,
            trackedTab: 0,
            related: [
                props.product.relatedProducts.product1,
                props.product.relatedProducts.product2,
                props.product.relatedProducts.product3,
                props.product.relatedProducts.product4,
            ],
            showRelated: props.product.relatedProducts.showRelatedProducts
        }
    }
    // Need to be an arrow function as we're passing down this method to child component and don't want to bind 'this'
    toggleOptions = (id) => {
        const option = this.state.colours[id]
        this.setState({
            option: option,
            mainImage: {
                url: option.image[0].sourceUrl,
                alt: option.image[0].altText,
            },
            thumbnails: option.image,
            dimensions: option.dimensions,
            desc: option.description,
            spec: option.specifications,
            checkbox: id,
        })
    }
    
    updateImage = (id) => {
        this.setState({
            mainImage: {
                url: this.state.option.image[id].sourceUrl,
                alt: this.state.option.image[id].altText,
            },
        })
    }

    relatedEmpty() {
        let empty = this.state.related.filter(item => item )
        return empty.length !== 0 ? true : false
    }
 
    render() {
        return (
            <div className="container mx-auto">
                <div className="mb-16 md:mb-24">
                    <div className="flex flex-wrap">
                        <div className="w-full md:w-1/2 md:pr-12 lg:pr-24">
                            <ImageGallery 
                                toggle={this.updateImage}
                                thumbnails={this.state.thumbnails}
                                mainImageUrl={this.state.mainImage.url}
                                mainImageAlt={this.state.mainImage.alt} 
                            />                        
                        </div>
                        <div className="w-full md:w-1/2 md:pl-8">
                            {this.state.title && <h1 className="text-4xl leading-normal">{this.state.title}</h1>}
                            <MainInfo price={this.state.price} dimensions={this.state.dimensions}/>
                            <Checkboxes title="Avaliable Options:" toggle={this.toggleOptions} colours={this.state.colours} checkbox={this.state.checkbox}/>
                            <div className="product__info">
                                <TabbedInfo desc={this.state.desc} info={this.state.spec} />
                            </div>
                        </div>
                    </div>
                </div>
                {(this.relatedEmpty() && this.state.showRelated) && <div>
                    <h3 className="px-4 text-3xl mb-8 text-center">Related Products</h3>
                    <div className="flex flex-wrap mb-8 md:mb-16">
                        {this.state.related.map((product, i) =>  (
                             product && <ProductItem key={i} product={product} />
                        ))}
                    </div>
                </div>}
                <Cta />
                <Emailer />
           </div>
           
        )
    }
}

export default Decking